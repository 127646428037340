import React,{useState,useMemo,useEffect} from "react";
import Orders from "../components/Orders";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { app } from "../store/appSlice";
const MainOrders=()=>{
const { orderFt } = useParams();
const [orders,setAllOrders]=useState([])
const dispatch=useDispatch()
const [selectedOrders,setOrders]=useState([])
const navigate=useNavigate()
const OrderItems=prop=>{
    return(<button onClick={()=>{
      navigate(`/order/items/${prop.id}`)
      dispatch(app.setOrder(prop.order))
    }} className="tb-btn-stl">
      Items
    </button>)
  }
  useEffect(()=>{
    async function getAllOrders(){
      //{ headers: {"Authorization" : `Bearer ${token}`} }
      try{
        const {data}=await axios.get(`${process.env.REACT_APP_APIURL}/allOrders`)
        const orders=data.map((item,index)=>{
          return{
            id:index+1,
            status:item.status,
            orderNo:item.orderNo,
            paidVia:item.paymentMethod,
            location:item.locationId,
            totalCost:"Ksh "+item.totalCost.toLocaleString()+'.0',
            name:item.customerName,
            contact:item.customerNumber,
            customerLocation:item.specificLocation,
            recipientLat:item.destinationLatitude,
            recipientLong:item.destinationLongitude,
            transactionRef:item.transactionRef,
            distance:item.distance,
            deliveryCost:"Ksh "+item.deliveryCost.toLocaleString()+'.0',
            date:formatedDate(item.orderDatetime)
          }
        })
        setAllOrders(orders)
       // dispatch(app.setOrders(orders))
      }
      catch(e){
        
    }
   }
   getAllOrders()
  },[])


const filteredData = useMemo(() => {
    const orderType=Number(orderFt)
    let all_orders=[]
    if(orderType===1){
        all_orders=orders.filter((item)=>item.status==="Received"||item.status==="Order Delivered"); 
    }
    if(orderType===2){
        all_orders=orders.filter((item)=>item.status==="Rider Enroute");
    }
    //Order Placed
    if(orderType===3){
        all_orders=orders.filter((item)=>item.status==="Order Placed");
        console.log("Orders placed ",all_orders)
    }
    if(orderType===4){
      all_orders=orders.filter((item)=>item.status==="Order Cancelled"||item.status==="System Cancelled");
    }
    const addBtn=all_orders.map((item)=>{
        return{...item,items:<OrderItems id={item.orderNo} order={item}/>}
    })
    return addBtn
   }, [orderFt,orders]);
   const formatedDate=(date)=>{
    if(date!==null){
      const dateAlone=date.split("T")
      return dateAlone[0].replaceAll("-","/")
    }
    return ""
  }
   useEffect(()=>{
    setOrders(filteredData)
   },[filteredData])
return(
   <Orders orders={selectedOrders}/>
)
}
export default MainOrders